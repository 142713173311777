import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Button,
	Content,
	Icon,
	IconEdit,
	Paragraph,
	TextContainer,
	Ul,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { Popover } from "./popover";

export const Meta: DocPageMeta = {
	category: "Overlay",
	title: "Popover",
	notepad: "https://hackmd.io/@Ulcvl-fvShmIdi46-hA8JQ/SJa_zbsHK",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Popover"
			subTitle="Display additional information or actions relevant to a specific element, appearing as a floating container anchored to the trigger."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Popover} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					Use Popovers <b>sparingly</b> to avoid cluttering the interface and distracting users from
					their primary tasks. Popover is composed of:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Trigger Element</b>: The button that, when interacted with, opens the popover. View{" "}
							<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>{" "}
							appearance. Consider using descriptive labels or icons for the trigger element to
							clarify its purpose.
						</>,
						<>
							<b>Popover Container</b>: The floating box that houses the popover content. Ensure
							sufficient padding and spacing within the container for readability.
						</>,
						<>
							<b>Content Area</b>: The space within the popover for text, icons, links, or other
							interactive elements. Prioritize the most important information at the top.
						</>,
						<>
							<b>Close Button</b> (optional): A button to dismiss the popover. Place it consistently
							(e.g., button-right corner) and use a recognizable icon (e.g., "x").
						</>,
						<>
							<b>Footer</b> (optional): Use{" "}
							<GatsbyLink to="/lab/components/Actions and controls/Action Bar">
								Action Bar
							</GatsbyLink>{" "}
							for actions or additional information related to the entire popover. Clearly separate
							the footer from the main content area visually. View <a href="#footer">Footer</a>{" "}
							variant
						</>,
					]}
				/>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					The default variant includes a button trigger with a chevron icon, popover content area,
					and optional close button. Here's an example of a default implementation of the{" "}
					<Code>Popover</Code> component. Assign the <Code>firstFocusableRef</Code> property to the
					first focusable element in the popover.
				</Paragraph>

				<Example fn={DefaultExample} />
				<Header.H3>Button appearance</Header.H3>
				<Paragraph>
					Customize the{" "}
					<GatsbyLink to="/lib/components/Actions and controls/Button">Button</GatsbyLink>'s
					appearance using the <Code>ButtonProps</Code> property. This allows you to change the
					button's style, size, and other visual attributes.
				</Paragraph>
				<Example fn={ButtonPropsExample} />
				<Paragraph>
					Hide the chevron icon using the <Code>hideChevron</Code> property if it's not needed.
				</Paragraph>
				<Example fn={HideChevronExample} />
				<Header.H3>Popover placement</Header.H3>
				<Paragraph>
					Choose a placement that ensures the popover is visible and doesn't obstruct important
					content. Use the <Code>placement</Code> property to control popover positioning relative
					to the trigger element.
				</Paragraph>

				<Example fn={PlacementExample} />
				<Header.H3>Start open</Header.H3>
				<Paragraph>
					Use the <Code>startOpen</Code> property to display the popover immediately when the page
					loads. This is generally not recommended for most use cases.
				</Paragraph>
				<Example fn={StartOpenExample} />
				<Header.H3>Footer</Header.H3>
				<Paragraph>
					Popovers can optionally contain a footer. This section is intended for information and
					actions that relate to the <Code>Popover</Code> as a whole. In most cases, that will be
					the{" "}
					<GatsbyLink to="/lab/components/Actions and controls/Action Bar">Action Bar</GatsbyLink>{" "}
					component, which provides users with a set of actions related to the completion of a task.
				</Paragraph>
				<Paragraph>
					Add a footer by placing a <Code>Popover.Footer</Code> component in <Code>Popover</Code> as
					its s its last child.
				</Paragraph>
				<Example fn={FooterExample} />
				<Header.H3>Long content</Header.H3>
				<Paragraph>
					When the content of a popover is lengthy, consider using a scrollable container to
					accommodate the text, ensuring that the popover remains a manageable size. Internally, the{" "}
					<Code>Popover</Code> dynamically adjusts the maximum height of the content area based on
					the viewport size. One can leverage, for instance, the <Code>overflowY: "auto"</Code> and{" "}
					<Code>maxHeight: "inherit"</Code> CSS properties to make the inner content scrollable on
					viewports with limited height. Moreover, if you want to limit the height of a popover
					regardless of the viewport size, consider setting <Code>maxHeight</Code> to a specific
					value, such as <Code>rem</Code> or <Code>vh</Code>, to maintain consistent sizing across
					different screen dimensions.
				</Paragraph>
				<Example fn={LongContentExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Popover}
					initialProps={{
						popoverContent: (id, firstFocusableRef, { setIsOpen }) => (
							<div id={id}>
								<Content>
									<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
										Close popover
									</Button>
								</Content>
							</div>
						),
						buttonContent: "Button",
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Popover</Code> to
					</Paragraph>
					<Ul
						items={[
							"Provide supplemental details or explanations that don't fit within the main interface flow.",
							"Offer actions related to an element but not critical enough for primary placement.",
							"Reveal additional content in limited space without cluttering the interface.",
							"Gradually introduce users to additional features or options.",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>Popover</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								<GatsbyLink
									to="/lab/components/Tables and lists/Table toolbar
"
								>
									Toolbar
								</GatsbyLink>
								: To provide customized views or options related to specific tools or actions.
							</>,
							<>
								Data Visualizations: To display detailed information about data points on charts or
								graphs when the user interacts with them.
							</>,
							<>
								Collaboration Tools: To offer commenting, annotation, or sharing options directly on
								elements like charts, cards, or text.
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>Popover</Code> to existing components for visual consistency.
							</>,
						]}
					/>
					<Header.H4>Interaction</Header.H4>
					<Ul
						items={[
							<>
								Popovers should open on click or hover (depending on user preferences and device
								capabilities).
							</>,
							<>
								Popovers should close when:{" "}
								<Ul
									items={[
										"The user clicks outside the popover.",
										"The user interacts with a trigger element for another popover.",
										"The user presses the Escape key.",
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Presenting large amounts of content. Use a{" "}
								<GatsbyLink to="/lab/components/Overlay/Modal">Modal</GatsbyLink> instead.
							</>,
							<>
								Displaying primary actions: Integrate critical actions directly into the main
								interface.
							</>,
							<>
								Providing simple explanations. Use a{" "}
								<GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip</GatsbyLink> for brief
								tooltips or definitions.
							</>,
							<>
								Providing option lists: Use a{" "}
								<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink>{" "}
								component for choosing from a set of options
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[<>Ensure sufficient color contrast between the popover and its background.</>]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Keep content concise and focused. ",
							"Avoid overwhelming users with excessive information.",
							"Use clear, actionable language for buttons and links.",
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const DefaultExample = () => (
	<Popover
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
			</div>
		)}
		buttonContent="Button"
	/>
);

const ButtonPropsExample = () => (
	<Popover
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
			</div>
		)}
		buttonContent="Button"
		buttonProps={{ variant: "primary", size: "large" }}
	/>
);

const HideChevronExample = () => (
	<Popover
		aria-label="Edit"
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
			</div>
		)}
		buttonContent={
			<Icon>
				<IconEdit />
			</Icon>
		}
		hideChevron
	/>
);

const PlacementExample = () => (
	<Popover
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
			</div>
		)}
		buttonContent="Button"
		placement="top"
	/>
);

const StartOpenExample = () => (
	<Popover
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
			</div>
		)}
		buttonContent="Button"
		startOpen
	/>
);

const FooterExample = () => (
	<Popover
		popoverContent={(id, firstFocusableRef, { setIsOpen }) => (
			<div id={id}>
				<Content>
					<Button onClick={() => setIsOpen(false)} ref={firstFocusableRef}>
						Close popover
					</Button>
				</Content>
				<Popover.Footer>
					<ActionBar
						primary={{ children: "Primary", onClick: console.log }}
						cancel={{ children: "Cancel", onClick: console.log }}
					/>
				</Popover.Footer>
			</div>
		)}
		buttonContent="Button"
	/>
);

const LongContentExample = () => (
	<Popover
		popoverContent={(id) => (
			<Content
				id={id}
				style={{
					overflowY: "auto",
					maxHeight: "inherit",
				}}
			>
				<Paragraph>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed commodo quam ac urna laoreet,
					ut venenatis magna sodales. Fusce consequat eget tortor ac luctus. Donec libero urna,
					facilisis quis tristique vel, sollicitudin nec ligula. Nullam bibendum dolor urna, vitae
					pretium urna faucibus eu. Fusce tempor quam dolor, id porttitor est commodo id. Nullam at
					laoreet arcu, nec sodales ligula. Vestibulum lacinia feugiat nunc, id molestie elit
					feugiat dictum. Aliquam posuere ultricies sem, ut vehicula mi lobortis eget. Duis ac leo
					ante. Vestibulum ornare libero interdum, tincidunt nunc at, vulputate urna. Sed rutrum
					arcu cursus nisi ullamcorper, elementum congue sapien eleifend.
				</Paragraph>
				<Paragraph>
					Mauris nec viverra lorem. Morbi ut dui vel enim volutpat faucibus sed nec nibh. Ut quis
					porttitor elit, ut posuere tortor. Duis placerat blandit odio, ac sodales neque.
					Vestibulum dui ex, feugiat facilisis nunc non, placerat varius urna. Vivamus mauris dolor,
					scelerisque at enim non, pulvinar porttitor lectus. Phasellus scelerisque quam vel quam
					elementum, ut pulvinar sapien fringilla. Integer et leo bibendum quam accumsan euismod.
				</Paragraph>
				<Paragraph>
					Duis suscipit, nisl et tempus mollis, lacus metus aliquam lorem, ac euismod tortor urna at
					sem. Nullam accumsan iaculis lacus, eget egestas mauris posuere ut. Suspendisse potenti.
					Etiam vitae justo lacinia, dapibus lorem non, ullamcorper augue. Pellentesque finibus
					sagittis libero, non venenatis turpis rhoncus dictum. Quisque vulputate dolor dui, at
					tincidunt odio tincidunt sit amet. Morbi nibh libero, porta sit amet aliquam vel, pretium
					id ipsum.
				</Paragraph>
				<Paragraph>
					Ut vel rutrum elit, nec mattis ante. Orci varius natoque penatibus et magnis dis
					parturient montes, nascetur ridiculus mus. Sed efficitur quis augue ut bibendum. Curabitur
					consequat, est at accumsan aliquam, arcu arcu gravida sapien, eget fringilla ipsum nunc
					sit amet orci. Proin et semper elit. Quisque ultricies arcu id ligula ullamcorper,
					tincidunt sodales est hendrerit. Mauris pretium nibh in velit fermentum euismod.
				</Paragraph>
				<Paragraph>
					Phasellus varius leo odio. Vivamus porta bibendum quam eget finibus. Phasellus iaculis
					pretium justo laoreet rutrum. Ut sollicitudin dictum odio vel varius. Proin lacinia
					efficitur quam, eget facilisis tortor pellentesque in. Praesent blandit tellus in massa
					vehicula, vitae hendrerit nisi dictum. Fusce id pellentesque arcu, vitae accumsan nunc.
				</Paragraph>
			</Content>
		)}
		buttonContent="Button"
	/>
);
